import styled from 'styled-components';

const Content = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;

  & > p > img,
  & > img {
    width: 100%;
  }
`;

export default Content;
