import React from 'react';
import { graphql } from 'gatsby';
import { Typography, Container, Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import SEO from '../components/seo';
import { ReleaseBySlugQuery } from '../../types/graphql-types';
import RequireAuthDownloadButton from '../components/button/require-auth--download-button';
import Content from '../components/Content';
import Link from '../components/Link';

export interface Release {
  data: ReleaseBySlugQuery;
}

const Release = ({ data }: Release): JSX.Element => {
  const { contentfulBimxplorerSetup } = data;

  return (
    <>
      <SEO title={contentfulBimxplorerSetup?.title || ''} />
      <Box mb={5} mt={5}>
        <Container>
          <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
            {contentfulBimxplorerSetup?.version}
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph>
            {contentfulBimxplorerSetup?.title}
          </Typography>
          <Typography variant="h6" align="left" color="textSecondary" component="p">
            {contentfulBimxplorerSetup?.publishDate}
          </Typography>
          <Content
            dangerouslySetInnerHTML={{
              __html: contentfulBimxplorerSetup?.releaseNotes?.childMarkdownRemark?.html || '',
            }}
          />
          <Typography component="h2" variant="h4" align="left" color="textPrimary" gutterBottom>
            Attachments
          </Typography>
          <Typography>
            {contentfulBimxplorerSetup?.setupFile?.file?.url && (
              <RequireAuthDownloadButton
                href={(contentfulBimxplorerSetup?.setupFile?.file?.url as string) || undefined}
                color="primary"
              >
                <GetAppIcon />
                Download ({contentfulBimxplorerSetup?.version})
              </RequireAuthDownloadButton>
            )}
          </Typography>
          <Typography>
            {contentfulBimxplorerSetup?.documentation?.file?.url && (
              <Link
                href={(contentfulBimxplorerSetup?.documentation?.file?.url as string) || undefined}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contentfulBimxplorerSetup?.documentation?.file?.url
                  ? 'Documentation'
                  : 'See previous'}
              </Link>
            )}
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Release;

export const pageQuery = graphql`
  query ReleaseBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBimxplorerSetup(slug: { eq: $slug }) {
      version
      releaseNotes {
        childMarkdownRemark {
          html
        }
      }
      setupFile {
        file {
          url
        }
      }
      documentation {
        file {
          url
        }
      }
      title
      publishDate(formatString: "MMMM Do, YYYY")
    }
  }
`;
